import React from 'react'
import { ExecutionTrace } from './TreeNodeTypes'

type Props = {
  execution: ExecutionTrace
}
export default function InputPanel({ execution }: Props): JSX.Element {
  const json = JSON.stringify(execution.inputParameters, undefined, 2)
  return <pre id="json">{json}</pre>
}
