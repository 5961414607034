import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import type { TRootState } from '.'
import type { DecisionTreeVersion } from './schemas'
import { createApiCall } from './common/standard-request'
import { apiStateSelectors } from './ApiStateStore'

// check https://redux-toolkit.js.org/api/createEntityAdapter
const decisionTreeVersionAdapter = createEntityAdapter<DecisionTreeVersion>({
  selectId: (decisionTreeVersion: DecisionTreeVersion) => decisionTreeVersion.id,
  sortComparer: (a, b) => (a.version < b.version ? 1 : -1),
})

export const decisionTreeVersionSlice = createSlice({
  name: 'decisionTreeVersion',
  initialState: decisionTreeVersionAdapter.getInitialState(),
  reducers: {
    setAll: decisionTreeVersionAdapter.setAll,
    setCurrent: decisionTreeVersionAdapter.setOne,
    updateOne: decisionTreeVersionAdapter.updateOne,
  },
})

export default decisionTreeVersionSlice.reducer

const FETCH_ALL = 'decisionTreeVersion/fetchAll'
const fetchdecisionTreeVersions = (decisionTreeId: string | undefined) =>
  createApiCall(FETCH_ALL, 'GET', `/decisionTree/${decisionTreeId}/Versions`, decisionTreeVersionSlice.actions.setAll)

const FETCH = 'decisionTreeVersion/fetch'
const fetchdecisionTreeVersionById = (decisionTreeVersionId: string | undefined) =>
  createApiCall(
    FETCH,
    'GET',
    `/decisionTree/Version/${decisionTreeVersionId}`,
    decisionTreeVersionSlice.actions.setCurrent,
  )

const PUBLISH_TREE_VERSION = 'decisionTreeVersion/publish'
const publishTreeVersion = (data: { treeId: string; versionId: string }) =>
  createApiCall(
    PUBLISH_TREE_VERSION,
    'POST',
    `/decisionTree/${data.treeId}/versions/${data.versionId}/publish`,
    decisionTreeVersionSlice.actions.updateOne,
  )

const NEW_TREE_VERSION = 'decisionTreeVersion/create'
const createTreeVersion = (data: Partial<DecisionTreeVersion>, treeId: string) =>
  createApiCall(
    PUBLISH_TREE_VERSION,
    'POST',
    `/decisionTree/${treeId}/versions`,
    decisionTreeVersionSlice.actions.setCurrent,
    data,
  )

const UPDATE_TREE_VERSION = 'decisionTreeVersion/update'
const updateTreeVersion = (data: Partial<DecisionTreeVersion>, treeId: string, versionId: string) =>
  createApiCall(
    UPDATE_TREE_VERSION,
    'PUT',
    `/decisionTree/${treeId}/versions/${versionId}`,
    decisionTreeVersionSlice.actions.setCurrent,
    data,
  )

export const decisionTreeActions = decisionTreeVersionSlice.actions

export const decisionTreeVersionApi = {
  fetchAll: fetchdecisionTreeVersions,
  fetch: fetchdecisionTreeVersionById,
  publish: publishTreeVersion,
  create: createTreeVersion,
  update: updateTreeVersion,
}

export const decisionTreeVersionSelectors = {
  ...decisionTreeVersionAdapter.getSelectors((state: TRootState) => state.decisionTreeVersion),
  isLoadingdecisionTreeList: (state: TRootState) => apiStateSelectors.isLoading(state, FETCH_ALL),
  isPublishingVersion: (state: TRootState) => apiStateSelectors.isLoading(state, PUBLISH_TREE_VERSION),
  isUpdatingVersion: (state: TRootState) => apiStateSelectors.isLoading(state, UPDATE_TREE_VERSION),
}
