import React, { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import Search from '@mui/icons-material/Search'
import { useNavigate, useParams } from 'react-router-dom'
import { EntityId } from '@reduxjs/toolkit'
import { useDispatchStdApiCall } from '../../store/common/standard-request'
import { useAppSelector } from '../../store'
import { decisionTreeTraceApi, decisionTreeTraceSelectors } from '../../store/DecisionTreeTraceStore'
import Trace from '../DecisionTreeTest/ShowTrace'
import { decisionTreeVersionApi, decisionTreeVersionSelectors } from '../../store/DecisionTreeVersionStore'
import { ExecutionTrace, TreeNode } from '../DecisionTreeTest/ShowTrace/TreeNodeTypes'

function index() {
  const { traceId } = useParams()

  const dispatchApi = useDispatchStdApiCall()
  const navigate = useNavigate()

  const [newTraceId, setNewTraceId] = useState('')
  const trace = useAppSelector((state) => decisionTreeTraceSelectors.selectById(state, traceId as EntityId))

  const decisionTree = useAppSelector((decisionTreeVersion) =>
    decisionTreeVersionSelectors.selectById(decisionTreeVersion, trace?.decisionTreeVersionId as EntityId),
  )

  const parseTrace = (): ExecutionTrace => {
    if (trace) {
      const { inputValues, output, stepTraces } = trace
      return {
        inputParameters: inputValues,
        outputValues: output,
        traces: stepTraces.map((node) => {
          return { ...node }
        }),
      }
    }

    return {
      inputParameters: null,
      outputValues: null,
      traces: [],
    }
  }

  useEffect(() => {
    if (traceId) {
      dispatchApi(decisionTreeTraceApi.fetch(traceId)).then((response: any) => {
        if (response?.data?.decisionTreeVersionId) {
          dispatchApi(decisionTreeVersionApi.fetch(response?.data?.decisionTreeVersionId))
        }
      })
    }
  }, [dispatchApi, traceId])

  const handleNewTraceId = () => {
    if (newTraceId !== '' && newTraceId !== undefined) {
      navigate(`../decision-tree/trace/${newTraceId}`, { replace: true })
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, marginTop: 50 }}>
        <TextField
          label="Id"
          variant="outlined"
          onChange={(newValue) => setNewTraceId(newValue.target.value)}
          value={newTraceId}
        />
        <Box sx={{ ml: 1 }}>
          <Button variant="contained" sx={{ mr: 1 }} startIcon={<Search />} onClick={handleNewTraceId}>
            Search
          </Button>
        </Box>
      </div>
      {trace !== undefined && decisionTree?.steps !== undefined && (
        <Trace tree={JSON.parse(JSON.stringify(decisionTree?.steps)) as TreeNode[]} trace={parseTrace()} />
      )}
    </>
  )
}

export default index
