import React, { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'

export default function LoginPage() {
  const { instance } = useMsal()

  useEffect(() => {
    if (!window.location.pathname.startsWith('/auth-page')) {
      localStorage.setItem('returnUrl', window.location.pathname)
      setTimeout(() => {
        instance.loginRedirect(loginRequest)
      }, 1000)
    }
  }, [])

  return <div>Logging in...</div>
}
