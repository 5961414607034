import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { EntityId } from '@reduxjs/toolkit'
import { ParseError, SchemaValidationError } from 'jsoneditor'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Breadcrumb } from '../../components'
import JSONEditorComponent, { JSONEditorMode } from '../../components/JSONEditorComponent'
import { useAppSelector } from '../../store'
import { useDispatchStdApiCall } from '../../store/common/standard-request'
import { decisionTreeVersionApi, decisionTreeVersionSelectors } from '../../store/DecisionTreeVersionStore'

function index() {
  const [json, setJson] = useState()
  const [refreshEditor, setRefreshEditor] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<JSONEditorMode>('view')
  const [version, setVersion] = useState<any>()
  const { id, treeId } = useParams()
  const dispatchApi = useDispatchStdApiCall()

  const setDecisonTree = async () => {
    const data = await useAppSelector((decisionTreeVersion) =>
      decisionTreeVersionSelectors.selectById(decisionTreeVersion, id as EntityId),
    )
    setVersion(data)
    await setJson(version?.steps)
    setRefreshEditor(true)
  }

  setDecisonTree()

  useEffect(() => {
    if (version?.id == null) dispatchApi(decisionTreeVersionApi.fetchAll(treeId)).then(() => setDecisonTree())
  }, [dispatchApi, version?.id])

  const breadcrumb = [
    { path: '/', name: 'home' },
    { path: '/decision-tree', name: 'decisionTree' },
    { path: `/decision-tree/${treeId}/view-version/${id}`, name: 'viewVersion' },
  ]

  const onChangeJSON = (jsonV: any) => null

  const onErrorJson = (errors: ReadonlyArray<SchemaValidationError | ParseError>) => null

  const onViewModeChange = (event: SelectChangeEvent) => {
    setViewMode(event.target.value as JSONEditorMode)
  }

  return (
    <div>
      <Breadcrumb trees={breadcrumb} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField disabled label="Numero de Version" fullWidth value={version?.version || ''} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField disabled label="Commentaire" fullWidth value={version?.comment || ''} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            label="Date de création"
            fullWidth
            value={new Date(version?.createdOn).toLocaleString() || ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            label="Date de modification"
            fullWidth
            value={new Date(version?.updatedOn).toLocaleString() || ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            label="Date de publication"
            fullWidth
            value={new Date(version?.updatedOn).toLocaleString() || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ m: 1, minWidth: 120, margin: 0 }}>
            <InputLabel id="select-label">View Mode</InputLabel>
            <Select labelId="select-label" value={viewMode} label="View Mode" onChange={onViewModeChange}>
              <MenuItem value="view">View</MenuItem>
              <MenuItem value="preview">Preview</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <JSONEditorComponent
        json={json}
        onChangeJSON={onChangeJSON}
        onValidationError={onErrorJson}
        mode={viewMode}
        refresh={refreshEditor}
      />

      <Grid container spacing={2} textAlign="center">
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Input Parameters
          </Typography>
          <Box sx={{ boxShadow: 3, borderColor: 'rgb(0, 0, 89)', border: '1px solid' }}>
            <Table sx={{ maxHeight: 350, padding: 50 }}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Nom</TableCell>
                  <TableCell align="center">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {version?.inputParameters.map((input: any) => (
                  <TableRow key={input?.propertyName}>
                    <TableCell>{input?.propertyName}</TableCell>
                    <TableCell align="center">{input?.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} textAlign="center">
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Output Parameters
          </Typography>
          <Box sx={{ boxShadow: 3, borderColor: 'rgb(0, 0, 89)', border: '1px solid' }}>
            <Table sx={{ maxHeight: 350, padding: 50 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell align="center">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {version?.outputParameters.map((output: any) => (
                  <TableRow key={output?.propertyName}>
                    <TableCell>{output?.propertyName}</TableCell>
                    <TableCell align="center">{output?.typeId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default index
