import * as yup from 'yup'

export const inputOutputParametersSchema = yup.object({
  propertyName: yup.string().required(),
  typeId: yup.string().required(),
  description: yup.string().nullable(),
})

export const DecisionTreeSchema = yup.object({
  id: yup.string().default(null), // le default null est nécessaire pour le createEntityAdapter
  name: yup.string().required(),
  description: yup.string(),
  createdOn: yup.date(),
  updatedOn: yup.date(),
})

export const DecisionTreeVersionSchema = yup.object({
  id: yup.string().default(null),
  decisionTreeId: yup.string().required(),
  version: yup.number().required(),
  comment: yup.string(),
  createdOn: yup.date(),
  updatedO: yup.date(),
  publishedOn: yup.date(),
  steps: yup.array<any>(),
  inputParameters: yup.array(inputOutputParametersSchema),
  outputParameters: yup.array(inputOutputParametersSchema),
})

export const InputParametersModelSchema = yup.object({
  id: yup.string().default(null),
  name: yup.string().required(),
  description: yup.string(),
  createdOn: yup.date(),
  updatedOn: yup.date(),
})

export const InputParametersModelVersionSchema = yup.object({
  id: yup.string().default(null),
  modelId: yup.string().required(),
  version: yup.number().required(),
  comment: yup.string(),
  createdOn: yup.date(),
  updatedOn: yup.date(),
  publishedOn: yup.date(),
  properties: yup.object(),
})

export type DecisionTreeVersion = yup.InferType<typeof DecisionTreeVersionSchema>
export type DecisionTree = yup.InferType<typeof DecisionTreeSchema>
export type InputParametersModel = yup.InferType<typeof InputParametersModelSchema>
export type InputParametersModelVersion = yup.InferType<typeof InputParametersModelVersionSchema>
