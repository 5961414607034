import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import type { TRootState } from '.'
import type { InputParametersModel, InputParametersModelVersion } from './schemas'
import { createApiCall } from './common/standard-request'
import { apiStateSelectors } from './ApiStateStore'

// check https://redux-toolkit.js.org/api/createEntityAdapter

const decisionTreeModelAdapter = createEntityAdapter<InputParametersModel>({
  selectId: (decisionTreeVersion: InputParametersModel) => decisionTreeVersion.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const decisionTreeModeVersionlAdapter = createEntityAdapter<InputParametersModelVersion>({
  selectId: (decisionTreeVersion: InputParametersModelVersion) => decisionTreeVersion.id,
})

export const decisionTreeModelSlice = createSlice({
  name: 'decisionTreeModel',
  initialState: decisionTreeModelAdapter.getInitialState(),
  reducers: {
    addMany: decisionTreeModelAdapter.addMany,
  },
})

export const decisionTreeModelVersionSlice = createSlice({
  name: 'decisionTreeModelVersion',
  initialState: decisionTreeModeVersionlAdapter.getInitialState(),
  reducers: {
    setAll: decisionTreeModeVersionlAdapter.setAll,
    addOne: decisionTreeModeVersionlAdapter.addOne,
  },
})

export default decisionTreeModelSlice.reducer

const FETCH_ALL = 'decisionTreeModel/fetchAll'
const fetchModel = () => createApiCall(FETCH_ALL, 'GET', '/model', decisionTreeModelSlice.actions.addMany)

const GET_MODEL_VERSIONS = 'model/versions'
const getModelVersions = (id: string) =>
  createApiCall(GET_MODEL_VERSIONS, 'GET', `/model/${id}/versions`, decisionTreeModelVersionSlice.actions.setAll)

const GET_MODEL_VERSION = 'model/version'
const getModelVersion = (id: string) =>
  createApiCall(GET_MODEL_VERSION, 'GET', `/ModelVersions/${id}`, decisionTreeModelVersionSlice.actions.addOne)

export const decisionTreeActions = decisionTreeModelSlice.actions

export const decisionTreeModelApi = {
  fetchAll: fetchModel,
  fechAllModelVersions: getModelVersions,
  fechVersion: getModelVersion,
}

export const decisionTreeModelSelectors = {
  ...decisionTreeModelAdapter.getSelectors((state: TRootState) => state.decisionTreeModel),
  isLoadingdecisionTreeList: (state: TRootState) => apiStateSelectors.isLoading(state, FETCH_ALL),
}

export const decisionTreeModelVersionSelectors = {
  ...decisionTreeModeVersionlAdapter.getSelectors((state: TRootState) => state.decisionTreeModelVersion),
  isLoadingdecisionTreeList: (state: TRootState) => apiStateSelectors.isLoading(state, FETCH_ALL),
  isLoadingModelVersionList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_MODEL_VERSIONS),
  isLoadingModelVersion: (state: TRootState) => apiStateSelectors.isLoading(state, GET_MODEL_VERSION),
}
