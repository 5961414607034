import { omit } from 'lodash'
import { createSlice, PayloadAction, ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import type { User, UserRight, UserRights } from './schemas'
import type { TRootState } from '.'
import { appActions } from './AppStore'
import { msalConfig } from '../authConfig'
import { getMsalInstance } from '../services/Authentification'

type UserDto = {
  id: string
  username: string
  name: string
  email: string
  officeId: string
  officeName: string
  officeProvince: string
  officeTypeId: string
  langId: string
  rights: string
  roles: string
  isInternal: string
  specialRights: string
  exp: number /* expire date in ms. to get the date, do new Date(user.exp * 1000) */
}

function parseJwt(token: string): object {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

interface UserStore {
  user: User
  needToAuthenticate: boolean
}

const initialState: UserStore = {
  user: {} as User,
  needToAuthenticate: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMustAuthenticate: (state, action: PayloadAction<boolean>) => {
      state.needToAuthenticate = action.payload
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.needToAuthenticate = false
    },
  },
})

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions

export default userSlice.reducer

// Other code such as selectors can use the imported `RootState` type
export const userSelectors = {
  getUser: (state: TRootState) => state.user.user,
  needToAuthenticate: (state: TRootState) => state.user.needToAuthenticate,
}

function buildRights(jwtRights: UserRight[]): UserRights {
  const ret = {} as UserRights
  jwtRights.forEach((r: UserRight) => {
    ret[r] = true
  })
  return ret
}

function loadUserFromJwtString(jwtToken: string): User {
  const dto = parseJwt(jwtToken) as UserDto
  const user: User = {
    ...omit(dto, ['roles', 'rights']),
    isInternal: dto.isInternal === 'true',
    roles: dto.roles.split(','),
    rights: buildRights(dto.rights.split(',') as UserRight[]),
  }
  return user
}

export const userEffects = {
  loadUser: (dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) => {
    const msalInstance = getMsalInstance()

    const accounts = msalInstance?.getActiveAccount()

    let user: User | null = null
    const jwtToken = localStorage.getItem('jwtToken')

    if (jwtToken) {
      user = loadUserFromJwtString(jwtToken)

      if (new Date(user.exp * 1000) <= new Date()) {
        user = null
      } else {
        dispatch(userActions.setUser(user))
        dispatch(appActions.setJwtToken(jwtToken))
      }
    }

    if (!accounts || !user) {
      dispatch(userActions.setMustAuthenticate(true))
    }
  },
}
