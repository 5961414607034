import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from '../../store'
import { userSelectors } from '../../store/UserStore'
import { UserRight } from '../../store/schemas'
import NotAuthorizedPage from './components/UnauthorizedPage'

type Props = {
  requiredPermission: UserRight
}

export default function RouteGuard({ requiredPermission }: Props) {
  const user = useAppSelector(userSelectors.getUser)
  if (!user?.rights) return <NotAuthorizedPage />

  return user.rights[requiredPermission] === true ? <Outlet /> : <NotAuthorizedPage />
}
