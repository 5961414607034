import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userEffects, userSelectors } from '../../store/UserStore'
import { useAppSelector } from '../../store'

export default function AuthPage(): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mustAuthenticate = useAppSelector(userSelectors.needToAuthenticate)
  const user = useAppSelector(userSelectors.getUser)

  useEffect(() => {
    if (!mustAuthenticate) userEffects.loadUser(dispatch)
  }, [navigate, dispatch])

  useEffect(() => {
    const returnUrl = localStorage.getItem('returnUrl')
    if (returnUrl) navigate(returnUrl)
    else if (user?.rights) {
      if (user.rights.viewDecisionTree) navigate('/admin', { replace: true })
      else navigate('/decision-tree/trace', { replace: true })
    }
  }, [user])
  return <div />
}
