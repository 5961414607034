import React, { useEffect } from 'react'
import { Box, Grid, Divider, List, ListItem, ListItemButton, ListItemText, Button, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'

import { Breadcrumb } from '../../components'
import { useDispatchStdApiCall } from '../../store/common/standard-request'
import { decisionTreeApi, decisionTreeSelectors } from '../../store/DecisionTreeStore'
import { useAppSelector } from '../../store'
import TreeVersions from './components/TreeVersions'
import CreateTreeDialogs from './components/newTreeDialog'
import { DecisionTree } from '../../store/schemas'

export default function App(): JSX.Element {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [editTree, setEditTree] = React.useState<DecisionTree | undefined>()
  const { t } = useTranslation()

  const dispatchApi = useDispatchStdApiCall()

  const decisionTreeList = useAppSelector(decisionTreeSelectors.selectAll)

  useEffect(() => {
    if ((window as any).activeIndex) setActiveIndex((window as any).activeIndex)
    dispatchApi(decisionTreeApi.fetchAll())
  }, [dispatchApi])

  const breadcrumb = [
    { path: '/', name: 'home' },
    { path: '/decision-tree', name: 'decisionTree' },
  ]

  const handleItemClick = (index: number) => {
    setActiveIndex(index)
    ;(window as any).activeIndex = index
  }

  const [openNewTreeDialog, setOpenNewTreeDialog] = React.useState(false)

  const handleClickOpen = () => {
    setOpenNewTreeDialog(true)
  }

  const handleClickClose = () => {
    setEditTree(undefined)
    setOpenNewTreeDialog(false)
  }

  return (
    <div>
      <CreateTreeDialogs open={openNewTreeDialog} close={handleClickClose} decisionTree={editTree} />
      <Breadcrumb trees={breadcrumb} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent="center" textAlign="center">
          <Grid item xs={6} md={4}>
            <Button
              variant="outlined"
              onClick={() => {
                setEditTree(undefined)
                handleClickOpen()
              }}
            >
              {t('decisionTree.newTree')}
            </Button>
            <nav>
              <List>
                {decisionTreeList.map((decisionTree, index: number) => (
                  <div key={decisionTree.id}>
                    <ListItem
                      disablePadding
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            setEditTree(decisionTree)
                            handleClickOpen()
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemButton selected={index === activeIndex} onClick={() => handleItemClick(index)}>
                        <ListItemText primary={decisionTree.name} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </nav>
          </Grid>
          <Grid item xs={6} md={8}>
            <TreeVersions decisionTree={decisionTreeList[activeIndex]} />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
