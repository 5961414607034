import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputPanel from './InputPanel'
import ConditionPanel from './ConditionPanel'
import OutputPanel from './OutputPanel'
import { TreeNode, ExecutionTrace, NodeTrace, PipelineBranch } from './TreeNodeTypes'
import { Mermaid } from '../../../components'

function pid(id: string): string {
  return id.replaceAll('-', '')
}

function renderNode(node: TreeNode, isUsed: boolean): string {
  const { id, name } = node
  const decoration = isUsed ? `class ${id} usedNode;` : ''

  if (node.branches !== null) return `${id}{${name}}; click ${id} callback; ${decoration}`
  if (node.decisionTable !== null) return `${id}(${name}); click ${id} callback; ${decoration}`
  return `${id}[${name}]; click ${id} callback; ${decoration}`
}

function renderLink(fromId: string, toId: string, isUsed: boolean, name: string | null = null) {
  const arrow = isUsed ? '-->' : '-.->'
  const label = name !== null ? `|${name}|` : ''

  return `${fromId} ${arrow} ${label} ${toId}; `
}

function renderNodes(steps: TreeNode[], nextStep: TreeNode | null, tracesById: Record<string, NodeTrace>): string {
  let prevIsUsed = !!tracesById[steps[0].id]
  let text = renderNode(steps[0], prevIsUsed)
  let from
  let to
  const nodes = steps as TreeNode[]
  let prevNode = steps[0]

  for (let i = 1; i < steps.length; i++) {
    const node = nodes[i]
    let nodeIsUsed = !!tracesById[node.id]

    text += renderNode(node, nodeIsUsed)

    from = prevNode.id
    to = node.id

    if (prevNode.branches === null) {
      text += renderLink(from, to, prevIsUsed && nodeIsUsed)
    }

    prevIsUsed = nodeIsUsed
    if (node.branches !== null) {
      let branchNext
      if (i === steps.length - 1) branchNext = nextStep
      else branchNext = steps[i + 1]

      from = to
      for (let j = 0; j < node.branches.length; j++) {
        to = pid(node.branches[j].steps[0].id)
        text += renderNodes(node.branches[j].steps, branchNext, tracesById)
        nodeIsUsed = !!tracesById[node.branches[j].id]
        text += renderLink(from, to, prevIsUsed && nodeIsUsed, node.branches[j].name)
      }
    }

    prevNode = node
  }

  if (nextStep !== null && prevNode.branches === null) {
    from = pid(prevNode.id)
    to = pid(nextStep.id)
    const nodeIsUsed = !!tracesById[to]
    text += renderLink(from, to, nodeIsUsed && prevIsUsed)
  }
  return text
}

function convertIds(nodes: TreeNode[]) {
  nodes.forEach((node) => {
    node.id = pid(node.id)
    if (node.branches) {
      node.branches.forEach((branch) => {
        branch.id = pid(branch.id)
        convertIds(branch.steps)
      })
    }
  })
  return nodes
}

function getNodesById(nodes: TreeNode[], dict: Record<string, TreeNode | PipelineBranch>) {
  nodes.forEach((node) => {
    dict[node.id] = node
    if (node.branches) {
      node.branches.forEach((branch) => {
        dict[branch.id] = branch
        getNodesById(branch.steps, dict)
      })
    }
  })
}

function getNodeTracesById(execution: ExecutionTrace): Record<string, NodeTrace> {
  const ret: Record<string, NodeTrace> = {}
  execution.traces.forEach((trace) => {
    trace.nodeId = pid(trace.nodeId)
    ret[trace.nodeId] = trace
  })
  return ret
}

const computePreviousOutputAtNodeId = (traces: NodeTrace[]): Record<string, Record<string, string>> => {
  const ret: Record<string, Record<string, string>> = {}
  let prevState: Record<string, string> = {}
  for (let i = 0; i < traces.length; i++) {
    const trace = traces[i]
    ret[trace.nodeId] = prevState
    if (trace.outputs) {
      const newState = { ...prevState }
      trace.outputs.forEach((output) => {
        newState[output.propertyName] = output.value
      })
      prevState = newState
    }
  }

  return ret
}

type Props = { tree: TreeNode[]; trace: ExecutionTrace }

export default function ShowTrace({ tree, trace }: Props): JSX.Element {
  const allNodes = convertIds(tree)
  const tracesById = getNodeTracesById(trace)
  const previousOutputAtNodeId = computePreviousOutputAtNodeId(trace.traces as NodeTrace[])
  const nodesById = {}
  getNodesById(allNodes, nodesById)

  const text = `flowchart TD\n\r ${renderNodes(allNodes, null, tracesById)}`
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null)
  return (
    <Grid container spacing={2} overflow="hidden" height="100%">
      <Grid
        item
        md={8}
        justifyContent="center"
        textAlign="center"
        height="100%"
        borderColor="red"
        border={1}
        overflow="scroll"
      >
        <Mermaid chart={text} onItemClicked={setSelectedNodeId} />
      </Grid>
      <Grid item md={4} height="100%" overflow="scroll">
        <h4>Données reçues</h4>
        <Box component="div" sx={{ overflow: 'auto', maxHeight: '100px', border: 1, margin: 2 }}>
          <InputPanel execution={trace} />
        </Box>
        <h4>Conditions</h4>
        <Box component="div" sx={{ overflow: 'auto', height: '200px', border: 1, margin: 2 }}>
          <ConditionPanel nodeTracesById={tracesById} selectedNodeId={selectedNodeId} nodesById={nodesById} />
        </Box>
        <h4>Résultats</h4>
        <Box component="div" flex={1} sx={{ overflow: 'auto', border: 1, margin: 2 }}>
          <OutputPanel
            nodeTracesById={tracesById}
            selectedNodeId={selectedNodeId}
            previousOutputAtNodeId={previousOutputAtNodeId}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
