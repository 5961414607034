import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import type { TRootState } from '.'
import { createApiCall } from './common/standard-request'
import { apiStateSelectors } from './ApiStateStore'
import { ExecutionTraceSaved } from '../containers/DecisionTreeTest/ShowTrace/TreeNodeTypes'

// check https://redux-toolkit.js.org/api/createEntityAdapter
const decisionTreeAdapter = createEntityAdapter<ExecutionTraceSaved>({
  selectId: (trace: ExecutionTraceSaved) => trace.id,
})

export const decisionTreeTraceSlice = createSlice({
  name: 'decisionTreeTrace',
  initialState: decisionTreeAdapter.getInitialState(),
  reducers: {
    setCurrent: decisionTreeAdapter.setOne,
  },
})

export default decisionTreeTraceSlice.reducer

const FETCH = 'decisionTreeTrace/fetch'
const fetchDecisionTreeTrace = (traceId: string) =>
  createApiCall(FETCH, 'GET', `/GetDecisionTreeExecution/${traceId}`, decisionTreeTraceSlice.actions.setCurrent)

export const decisionTreeTraceActions = decisionTreeTraceSlice.actions

export const decisionTreeTraceApi = {
  fetch: fetchDecisionTreeTrace,
}

export const decisionTreeTraceSelectors = {
  ...decisionTreeAdapter.getSelectors((state: TRootState) => state.decisionTreeTrace),
  isLoadingDecisionTreeList: (state: TRootState) => apiStateSelectors.isLoading(state, FETCH),
}
