import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import appReducer from './AppStore'
import userReducer from './UserStore'
import apiStateReducer from './ApiStateStore'
import decisionTreeReducer from './DecisionTreeStore'
import decisionTreeVersionReducer from './DecisionTreeVersionStore'
import decisionTreeModelReducer, { decisionTreeModelVersionSlice } from './DecisionTreeModelStore'
import decisionTreeTraceReducer from './DecisionTreeTraceStore'

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    decisionTree: decisionTreeReducer,
    decisionTreeVersion: decisionTreeVersionReducer,
    decisionTreeModel: decisionTreeModelReducer,
    decisionTreeModelVersion: decisionTreeModelVersionSlice.reducer,
    decisionTreeTrace: decisionTreeTraceReducer,
    api: apiStateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type TRootState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<TAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector
