import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { userSelectors } from '../../store/UserStore'
import { useAppSelector } from '../../store'

export default function HomePage(): JSX.Element {
  const navigate = useNavigate()
  const user = useAppSelector(userSelectors.getUser)

  useEffect(() => {
    if (user?.rights) {
      if (user.rights.viewDecisionTree) navigate('/admin', { replace: true })
      else navigate('/decision-tree/trace', { replace: true })
    }
  }, [navigate, user])

  return <div />
}
