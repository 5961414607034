import React, { useEffect, useState } from 'react'
import {
  alpha,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Send as SendIcon,
  Quiz as QuizIcon,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../../store'
import { useDispatchStdApiCall } from '../../../store/common/standard-request'
import { decisionTreeVersionApi, decisionTreeVersionSelectors } from '../../../store/DecisionTreeVersionStore'
import { DecisionTree, DecisionTreeVersion } from '../../../store/schemas'
import { appSelectors } from '../../../store/AppStore'
import { PageError, StyledTableCell, StyledTableRow } from '../../../components'

interface Column {
  id: 'version' | 'updatedOn' | 'comment' | 'action'
  label: string
  minWidth?: number
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: 'version', label: 'Name' },
  { id: 'updatedOn', label: 'Date\u00a0Publication' },
  { id: 'comment', label: 'Commentaire' },
  { id: 'action', label: 'Action', align: 'center' },
]

type Props = {
  decisionTree: DecisionTree
}

function TreeVersions({ decisionTree }: Props) {
  const [refresh, setRefresh] = useState<number>()
  const dispatchApi = useDispatchStdApiCall()

  const decisionTreeVersionList = useAppSelector(decisionTreeVersionSelectors.selectAll)
  const error = useAppSelector(appSelectors.getBusinessError)
  useEffect(() => {
    if (decisionTree?.id) dispatchApi(decisionTreeVersionApi.fetchAll(decisionTree?.id))
  }, [dispatchApi, decisionTree?.id, refresh])

  const isContaineUnpublished = (): boolean | undefined => {
    let disable = false
    for (let index = 0; index < decisionTreeVersionList.length; index += 1) {
      const element = decisionTreeVersionList[index]
      if (element.publishedOn == null) {
        disable = true
        break
      }
    }
    return disable
  }

  const navigate = useNavigate()
  const View = (id: string) => {
    const path = `/decision-tree/${decisionTree.id}/view-version/${id}`
    navigate(path)
  }

  const publishVersion = (version: DecisionTreeVersion) => {
    dispatchApi(decisionTreeVersionApi.publish({ treeId: version.decisionTreeId, versionId: version.id })).then(() =>
      setRefresh(Math.random()),
    )
  }

  return (
    <>
      <PageError error={error} />
      <Paper>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            textAlign: 'left',
          }}
        >
          <Grid container spacing={2} padding={1}>
            <Grid item sm={12}>
              <Typography sx={{ flex: '1 1 100%' }} variant="h4" component="div">
                {decisionTree?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9.5}>
              <Typography sx={{ flex: '1 1 100%' }} component="div">
                {decisionTree?.description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2.5} alignContent="end">
              <Button
                type="submit"
                variant="contained"
                disableElevation
                disabled={isContaineUnpublished()}
                href={`/decision-tree/${decisionTree?.id}/new-version`}
              >
                NOUVELLE VERSION
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer sx={{ maxHeight: 650 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.id} align={column?.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {decisionTreeVersionList.map((version, index: number) => (
                <StyledTableRow key={`_${version.id}`}>
                  <StyledTableCell>{version.version}</StyledTableCell>
                  <StyledTableCell>{version.publishedOn}</StyledTableCell>
                  <StyledTableCell>{version.comment}</StyledTableCell>
                  <StyledTableCell align="center">
                    {version.publishedOn ? (
                      <Tooltip title="View">
                        <IconButton aria-label="view" color="inherit" onClick={() => View(version.id)}>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          color="inherit"
                          href={`/decision-tree/${decisionTree?.id}/edit-version/${version.id}`}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Publish">
                      <span>
                        <IconButton
                          disabled={version.publishedOn != null}
                          aria-label="publish"
                          color="warning"
                          onClick={() => publishVersion(version)}
                        >
                          <SendIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Test">
                      <IconButton
                        aria-label="test"
                        color="info"
                        href={`/decision-tree/${decisionTree.id}/test/${version.id}`}
                      >
                        <QuizIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default TreeVersions
