import * as React from 'react'
import { styled } from '@mui/material/styles'
import { IconButton, DialogActions, DialogContent, DialogTitle, Dialog, Button, Grid, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatchStdApiCall } from '../../../store/common/standard-request'
import { DecisionTree, DecisionTreeSchema } from '../../../store/schemas'
import { PageError } from '../../../components'
import { useAppSelector } from '../../../store'
import { appSelectors } from '../../../store/AppStore'
import { decisionTreeApi, decisionTreeSelectors } from '../../../store/DecisionTreeStore'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = function (props: DialogTitleProps): JSX.Element {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

type Props = {
  open: boolean
  close: () => void
  decisionTree: DecisionTree | undefined
}

export default function CreateTreeDialogs({ open, close, decisionTree }: Props) {
  const { t } = useTranslation()
  const {
    resetField,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<DecisionTree>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    resolver: yupResolver(DecisionTreeSchema),
  })

  React.useEffect(() => {
    setValue('name', decisionTree?.name as string)
    setValue('description', decisionTree?.description as string)
  }, [decisionTree])

  const error = useAppSelector(appSelectors.getBusinessError)
  const isSaving = useAppSelector(decisionTreeSelectors.isSavingDecisionTree)
  const isUpdating = useAppSelector(decisionTreeSelectors.isUpdatingDecisionTree)

  const dispatchApi = useDispatchStdApiCall()

  const createTree = (data: DecisionTree) => {
    if (decisionTree !== undefined)
      dispatchApi(
        decisionTreeApi.update(
          { name: data.name, description: data.description, id: decisionTree.id },
          decisionTree.id,
        ),
      ).then(() => {
        resetField('name')
        resetField('description')
        close()
      })
    else
      dispatchApi(decisionTreeApi.create(data)).then(() => {
        resetField('name')
        resetField('description')
        close()
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(createTree)}>
        <input type="hidden" value={decisionTree?.id} {...register('id')} />
        <BootstrapDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
            {decisionTree !== undefined ? `Edit : ${decisionTree.name}` : t('decisionTree.newTree')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <PageError error={error} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  error={errors.name !== undefined}
                  id="name"
                  label="Nom"
                  value={getValues('name')}
                  fullWidth
                  {...register('name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  label="Description"
                  value={getValues('description')}
                  multiline
                  rows={4}
                  fullWidth
                  {...register('description')}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" disableElevation onClick={close} color="error">
              ANNULER
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={handleSubmit(createTree)}
              color="primary"
              disabled={isSaving || isUpdating}
            >
              {decisionTree !== undefined ? 'MODIFIER' : 'SAUVEGARDER'}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </form>
    </div>
  )
}
