import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { NodeTrace } from './TreeNodeTypes'

type Props = {
  nodeTracesById: Record<string, NodeTrace>
  previousOutputAtNodeId: Record<string, Record<string, string>>
  selectedNodeId: string | null
}

export default function OutputPanel({ previousOutputAtNodeId, nodeTracesById, selectedNodeId }: Props): JSX.Element {
  const id = selectedNodeId || ''
  const trace = nodeTracesById[id]
  if (!trace) return <div />

  const prevOutput = previousOutputAtNodeId[id]
  const processedProps: Record<string, boolean> = {}

  const outputs = Object.entries(prevOutput).map(([propName, value]) => {
    processedProps[propName] = true
    const existing = trace.outputs?.find((x) => x.propertyName === propName)
    if (existing && value !== existing.value) {
      return (
        <ListItem key={propName}>
          <ListItemText primary={`${propName} = `} />
          <ListItemText style={{ textDecoration: 'line-through', color: 'red' }} secondary={value} />
          <ListItemText primary={existing.value} style={{ color: 'green' }} />
        </ListItem>
      )
    }

    return (
      <ListItem key={propName}>
        <ListItemText primary={`${propName} = `} />
        <ListItemText primary={value} />
      </ListItem>
    )
  })

  trace.outputs?.forEach((output) => {
    if (processedProps[output.propertyName] !== true) {
      outputs.push(
        <ListItem key={output.propertyName}>
          <ListItemText primary={`${output.propertyName} = `} />
          <ListItemText primary={output.value} style={{ color: 'green' }} />
        </ListItem>,
      )
    }
  })

  return <List>{outputs}</List>
}
