import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import { createTheme, ThemeProvider } from '@mui/material'
import { MsalProvider } from '@azure/msal-react'
import { store } from './store'
import App from './containers/App/App'
import { initializeMsal } from './services/Authentification'
import initLanguage from './services/i18n'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400,
    },
  },
})

const msalInstance = initializeMsal()

initLanguage().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Suspense fallback="loading">
          <I18nextProvider i18n={i18n}>
            <Provider store={store}>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </Provider>
          </I18nextProvider>
        </Suspense>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})
