import { PayloadAction, createReducer } from '@reduxjs/toolkit'
import { Method } from 'axios'
import { TRootState } from '.'

const INITIATE_CALL = 'initiate api call'
const COMPLETED_CALL = 'completed api call'

type TState = {
  apiCallStates: Record<string, boolean>
}

export interface IApiCall {
  apiName: string
  method: Method
  url: string
  data: any
}

const initialState: TState = {
  apiCallStates: {},
}
// export default (state: WritableDraft<TState>, action: PayloadAction<IApiCall>): TState => {
//   if (state === null) return initialState
//   if (action.type.startsWith(INITIATE_CALL) || action.type.startsWith(COMPLETED_CALL)) {
//     const newApiState = { ...state.apiCallStates }
//     newApiState[action.payload.apiName] = action.type.startsWith(INITIATE_CALL)
//     return { ...state, apiCallStates: newApiState }
//   }
//   return state
// }

function isApiCall(action: any): boolean {
  return action.type.startsWith(INITIATE_CALL) || action.type.startsWith(COMPLETED_CALL)
}

export default createReducer(initialState, (builder) => {
  builder.addMatcher(isApiCall, (state: TState, action: PayloadAction<IApiCall>) => {
    state.apiCallStates[action.payload.apiName] = action.type.startsWith(INITIATE_CALL)
  })
})

const logApiCallInitiated = (payload: IApiCall): PayloadAction<IApiCall> => {
  return {
    type: `${INITIATE_CALL} ${payload.apiName}`,
    payload,
  }
}

const logApiCallCompleted = (payload: IApiCall): PayloadAction<IApiCall> => {
  return {
    type: `${COMPLETED_CALL} ${payload.apiName}`,
    payload,
  }
}

export const apiStateActions = {
  logApiCallInitiated,
  logApiCallCompleted,
}

export const apiStateSelectors = {
  isLoading: (state: TRootState, apiName: string) => state.api.apiCallStates[apiName] === true,
}
