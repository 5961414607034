/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import mermaid from 'mermaid'

const DEFAULT_CONFIG = {
  startOnLoad: false,
  theme: 'default',
  logLevel: 'fatal',
  securityLevel: 'loose',
  arrowMarkerAbsolute: false,
  flowchart: {
    useMaxWidth: true,
    htmlLabels: true,
    curve: 'cardinal',
  },
}

mermaid.initialize(DEFAULT_CONFIG)

function getId(node) {
  let current = node.target
  while (current !== null) {
    if (current.classList.contains('node')) {
      const parts = current.id.split('-')
      return parts[1]
    }

    current = current.parentElement
  }
  return null
}

function Mermaid({ chart, onItemClicked }) {
  // Mermaid initilize its config
  const node = useRef(null)
  const [html, setHtml] = useState(null)

  useEffect(() => {
    setHtml(mermaid.render('name', chart, undefined))
  }, [node.current])

  const handler = useCallback((e) => {
    const id = getId(e)
    if (onItemClicked && id) {
      onItemClicked(id)
    }
  })

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div onClick={handler} role="img" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
      <div ref={node} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default Mermaid
