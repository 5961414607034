import * as yup from 'yup'

export interface LoginCredentials {
  username: string
  password: string
}

export const LoginCredentialsSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
})
