import React from 'react'
import { Container, Typography, Box } from '@mui/material'

function UnauthorizedPage() {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          Unauthorized Access
        </Typography>
        <Typography variant="body1">You do not have permission to view this page.</Typography>
      </Box>
    </Container>
  )
}

export default UnauthorizedPage
