import React from 'react'
import { Alert, AlertTitle } from '@mui/material'

type Props = {
  error: string | null
}

export default function PageError({ error }: Props): JSX.Element | null {
  if (!error) return null
  return (
    <Alert severity="error" style={{ margin: 15 }}>
      <AlertTitle style={{ textAlign: 'left' }}>Error</AlertTitle>
      <span style={{ whiteSpace: 'pre-line' }}> {error}</span>
    </Alert>
  )
}
