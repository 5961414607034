import * as React from 'react'
import { AppBar, Container, Toolbar, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import LanguagePicker from './components/LanguagePicker'
import UserMenu from './components/UserMenu'
import AdminMenu from './components/AdminMenu'

export default function Layout(): JSX.Element {
  const menuStyle = { textDecoration: 'none' }

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'rgb(0, 0, 89)', color: 'white' }}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <a href="/secure/dashboard" className="icebergBanner">
            <img src="/images/iceberg-no-bg.png" alt="Iceberg Finances" style={{ width: '110px', height: '40px' }} />
          </a>
          <span style={{ flexGrow: 1 }}>&nbsp;</span>
          <AdminMenu />
          <Link to="/decision-tree/trace" style={menuStyle}>
            <Button>Trace</Button>
          </Link>
          <UserMenu />
          <LanguagePicker />
        </Toolbar>
      </Container>
    </AppBar>
  )
}
