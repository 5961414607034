import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import React from 'react'

type Props = { trees: Array<{ path: string | undefined; name: string }> }

function Breadcrumb({ trees }: Props): JSX.Element {
  const { t } = useTranslation()
  return (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {trees.map((value, index: number) => {
        return index === trees.length - 1 ? (
          <Typography key={value.name} color="rgb(0, 0, 89)" textTransform="uppercase" component="span">
            <h1>{t('breadcrumbs.'.concat(value.name))}</h1>
          </Typography>
        ) : (
          <Link
            key={value.name}
            href={value.path}
            underline="hover"
            style={{
              textTransform: 'uppercase',
              color: 'inherit',
            }}
          >
            <h1>{t('breadcrumbs.'.concat(value.name))}</h1>
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default Breadcrumb
