import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import type { TRootState } from '.'
import type { DecisionTree, DecisionTreeVersion } from './schemas'
import { createApiCall } from './common/standard-request'
import { apiStateSelectors } from './ApiStateStore'

// check https://redux-toolkit.js.org/api/createEntityAdapter
const decisionTreeAdapter = createEntityAdapter<DecisionTree>({
  selectId: (decisionTree: DecisionTree) => decisionTree.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const decisionTreeSlice = createSlice({
  name: 'decisionTree',
  initialState: decisionTreeAdapter.getInitialState(),
  reducers: {
    addMany: decisionTreeAdapter.addMany,
    setCurrent: decisionTreeAdapter.setOne,
  },
})

export default decisionTreeSlice.reducer

const FETCH_ALL = 'decisionTree/fetchAll'
const fetchdecisionTree = () => createApiCall(FETCH_ALL, 'GET', '/decisionTree', decisionTreeSlice.actions.addMany)

const CREATE_TREE = 'decisionTree/create'
const createTree = (data: Partial<DecisionTree>) =>
  createApiCall(CREATE_TREE, 'POST', '/decisionTree', decisionTreeSlice.actions.setCurrent, data)

const UPDATE_TREE = 'decisionTree/update'
const updateTree = (data: Partial<DecisionTree>, treeId: string) =>
  createApiCall(UPDATE_TREE, 'PUT', `/decisionTree/${treeId}`, decisionTreeSlice.actions.setCurrent, data)

export const decisionTreeActions = decisionTreeSlice.actions

export const decisionTreeApi = {
  fetchAll: fetchdecisionTree,
  create: createTree,
  update: updateTree,
}

export const decisionTreeSelectors = {
  ...decisionTreeAdapter.getSelectors((state: TRootState) => state.decisionTree),
  isLoadingDecisionTreeList: (state: TRootState) => apiStateSelectors.isLoading(state, FETCH_ALL),
  isSavingDecisionTree: (state: TRootState) => apiStateSelectors.isLoading(state, CREATE_TREE),
  isUpdatingDecisionTree: (state: TRootState) => apiStateSelectors.isLoading(state, UPDATE_TREE),
}
