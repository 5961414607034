import React, { useState } from 'react'
import {
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { StyledTableCell, StyledTableRow } from '../../../components'

export interface OutputModel {
  propertyName: string
  typeId: string
}

type Props = { initData: Array<OutputModel>; onUpdate: (data: Array<OutputModel>) => void }

function OutputParameters({ initData, onUpdate }: Props) {
  const [outputParameters, setOutputParameters] = useState<Array<OutputModel>>(initData)

  const {
    resetField,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
  })

  const onSetOutputParameters = (data: any) => {
    const update = [
      ...outputParameters,
      {
        propertyName: data.outputParametersName,
        typeId: data.outputParametersTypeId,
      },
    ]
    setOutputParameters(update)
    resetField('outputParametersName')
    onUpdate(update)
  }

  const removeOutputParameters = (index: number) => {
    const temp = []
    for (let i = 0; i < outputParameters.length; i += 1) {
      if (i !== index) temp.push(outputParameters[i])
    }
    setOutputParameters(temp)
    onUpdate(temp)
  }

  const type = ['bool', 'bool?', 'int', 'int?', 'date', 'date?', 'string', 'double', 'double?']

  return (
    <Grid container spacing={2} style={{ marginBottom: 15, marginTop: 15 }}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={2}>
          <TextField
            required
            error={errors?.outputParametersName !== undefined}
            label="Nom"
            fullWidth
            {...register('outputParametersName', { required: true })}
          />
          <FormControl sx={{ m: 1, minWidth: 120, margin: 0 }} fullWidth>
            <InputLabel id="select-model">Model</InputLabel>
            <Select
              error={errors?.outputParametersTypeId !== undefined}
              labelId="select-model"
              label="Model"
              defaultValue=""
              {...register('outputParametersTypeId', { required: true })}
            >
              {type.map((data: string) => (
                <MenuItem value={data} key={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Fab color="primary" aria-label="add" variant="extended" onClick={handleSubmit(onSetOutputParameters)}>
            <AddIcon />
            ADD
          </Fab>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TableContainer sx={{ maxHeight: 650 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Nom</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outputParameters.map((value: OutputModel, index: number) => (
                <StyledTableRow key={`_${value.propertyName}`}>
                  <StyledTableCell align="center">{value.propertyName}</StyledTableCell>
                  <StyledTableCell align="center">{value.typeId}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="View">
                      <IconButton aria-label="view" color="warning" onClick={() => removeOutputParameters(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default OutputParameters
