import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useAppSelector } from '../../../store'
import { useDispatchStdApiCall } from '../../../store/common/standard-request'
import {
  decisionTreeModelApi,
  decisionTreeModelSelectors,
  decisionTreeModelVersionSelectors,
} from '../../../store/DecisionTreeModelStore'
import { StyledTableCell, StyledTableRow } from '../../../components'

export interface InputModel {
  typeId: string
  propertyName: string
  model: string
  version: string
}

type Props = { treeId: string | undefined; initData: Array<InputModel>; onUpdate: (data: Array<InputModel>) => void }

function InputParameters({ treeId, initData, onUpdate }: Props) {
  const {
    resetField,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
  })

  const [inputParameters, setInputParameters] = useState<Array<InputModel>>(initData)

  const dispatchApi = useDispatchStdApiCall()

  const decisionTreeModelList = useAppSelector(decisionTreeModelSelectors.selectAll)

  const decisionTreeModelVersion = useAppSelector(decisionTreeModelVersionSelectors.selectAll)

  useEffect(() => {
    dispatchApi(decisionTreeModelApi.fetchAll())
  }, [dispatchApi, treeId])

  const removeInputParameters = (index: number) => {
    const temp = []
    for (let i = 0; i < inputParameters.length; i += 1) {
      if (i !== index) temp.push(inputParameters[i])
    }
    setInputParameters(temp)
    onUpdate(temp)
  }

  const onChangeModel = (event: SelectChangeEvent) => {
    dispatchApi(decisionTreeModelApi.fechAllModelVersions(event.target.value.split(' ')[1]))
  }

  const onSetInputParameters = (data: any) => {
    const arr = data.inputParametersModel.split(' ')
    const arrVersion = data.inputParametersModelVersion.split(' ')
    const update = [
      ...inputParameters,
      {
        typeId: `model(${arrVersion[1]})`,
        propertyName: data.inputParametersName,
        model: arr[0],
        version: arrVersion[0],
      },
    ]
    setInputParameters(update)
    resetField('inputParametersName')
    onUpdate(update)
  }

  return (
    <Grid container spacing={2} style={{ marginBottom: 15, marginTop: 15 }}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={2}>
          <TextField
            required
            error={errors?.inputParametersName !== undefined}
            label="Nom"
            fullWidth
            {...register('inputParametersName', { required: true })}
          />
          <FormControl sx={{ m: 1, minWidth: 120, margin: 0 }} fullWidth>
            <InputLabel id="select-model">Model</InputLabel>
            <Select
              error={errors?.inputParametersModel !== undefined}
              labelId="select-model"
              label="Model"
              defaultValue=""
              {...register('inputParametersModel', { required: true, onChange: onChangeModel })}
            >
              {decisionTreeModelList.map((value) => (
                <MenuItem value={`${value.name} ${value.id}`} key={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, margin: 0 }} fullWidth>
            <InputLabel id="select-version">Version</InputLabel>
            <Select
              error={errors?.inputParametersModelVersion !== undefined}
              labelId="select-version"
              label="Version"
              defaultValue=""
              {...register('inputParametersModelVersion', { required: true })}
            >
              {decisionTreeModelVersion.map((value) => (
                <MenuItem value={`${value.version} ${value.id}`} key={value.id}>
                  {value.version}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Fab color="primary" aria-label="add" variant="extended" onClick={handleSubmit(onSetInputParameters)}>
            <AddIcon />
            ADD
          </Fab>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TableContainer sx={{ maxHeight: 650 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Nom</StyledTableCell>
                <StyledTableCell align="center">Model</StyledTableCell>
                <StyledTableCell align="center">Version</StyledTableCell>
                <StyledTableCell align="center">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inputParameters.map((value: InputModel, index: number) => (
                <StyledTableRow key={`_${value.propertyName}`}>
                  <StyledTableCell align="center">{value.propertyName}</StyledTableCell>
                  <StyledTableCell align="center">{value.model}</StyledTableCell>
                  <StyledTableCell align="center">{value.version}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="View">
                      <IconButton aria-label="view" color="warning" onClick={() => removeInputParameters(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default InputParameters
