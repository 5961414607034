import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Container } from '@mui/material'
import AppHeader from './AppHeader'
import TopLevelRoutes from './Routes'

import { useAppDispatch, useAppSelector } from '../../store'
import { userEffects, userSelectors } from '../../store/UserStore'
import LoginPage from '../Login/LoginPage'

export default function App(): JSX.Element | null {
  const dispatch = useAppDispatch()
  const [isLoaded, setLoaded] = useState(false)
  const needAuth = useAppSelector(userSelectors.needToAuthenticate)

  useEffect(() => {
    userEffects.loadUser(dispatch)
    setLoaded(true)
  }, [dispatch])

  if (!isLoaded) return null

  return (
    <Router>
      {needAuth && <LoginPage />}
      {!needAuth && (
        <>
          <AppHeader />
          <Container maxWidth="xl" disableGutters>
            <TopLevelRoutes />
          </Container>
        </>
      )}
    </Router>
  )
}
