import * as React from 'react'
import { Menu, MenuItem, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function AdminMenu(): JSX.Element {
  const { t } = useTranslation()
  const menuStyle = { textDecoration: 'none' }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="btnAdmin"
        aria-controls={open ? 'admin-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Admin
      </Button>
      <Menu id="admin-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Link to="/admin" style={menuStyle}>
          <MenuItem>{t('menus.admin.decisionTree')}</MenuItem>
        </Link>
      </Menu>
    </div>
  )
}
