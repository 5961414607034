import React, { useEffect } from 'react'
import JSONEditor, { ParseError, SchemaValidationError } from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import './jsonEditor.css'
import { string } from 'yup'

export type JSONEditorMode = 'tree' | 'view' | 'form' | 'code' | 'text' | 'preview'

type Props = {
  onChangeJSON: ((json: any) => void) | undefined
  onValidationError: ((errors: ReadonlyArray<SchemaValidationError | ParseError>) => void) | undefined
  json: any
  mode: JSONEditorMode
  refresh: boolean
}

function JSONEditorComponent({ onChangeJSON, json, mode, onValidationError, refresh }: Props): JSX.Element {
  let jsoneditor: JSONEditor
  let container: HTMLElement | null
  useEffect(() => {
    const options = {
      mode,
      onChange: () => {
        if (onChangeJSON) onChangeJSON(jsoneditor.get())
      },
      onValidationError,
    }
    if (container) jsoneditor = new JSONEditor(container, options)
    jsoneditor.set(json)

    return () => {
      if (jsoneditor) {
        jsoneditor.destroy()
      }
    }
  }, [mode, refresh])

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      ref={(elem) => {
        container = elem
      }}
    />
  )
}

export default JSONEditorComponent
