import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig } from 'axios'
import type { TRootState } from '.'

export interface ApiState {
  apiName: string
  isLoading: boolean
}

interface AppState {
  globalTechnicalError: string | null
  businessError: string | null
  axiosBaseConfig: AxiosRequestConfig
}

const initialState: AppState = {
  globalTechnicalError: null,
  businessError: null,
  axiosBaseConfig: {
    baseURL: process.env.REACT_APP_DECISION_TREE_API_URL,
    withCredentials: true,
  },
}

export const appSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setTechnicalError: (state, action: PayloadAction<string>) => {
      state.globalTechnicalError = action.payload
    },
    setBusinessError: (state, action: PayloadAction<string>) => {
      state.businessError = action.payload
    },
    resetErrors: (state) => {
      state.businessError = null
      state.globalTechnicalError = null
    },
    setJwtToken: (state, action: PayloadAction<string>) => {
      state.axiosBaseConfig.headers = {
        Authorization: `Bearer ${action.payload}`,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const appActions = appSlice.actions

export default appSlice.reducer

// Other code such as selectors can use the imported `RootState` type
export const appSelectors = {
  getTechnicalError: (state: TRootState) => state.app.globalTechnicalError,
  getBusinessError: (state: TRootState) => state.app.businessError,
  getAxiosConfig: (state: TRootState) => state.app.axiosBaseConfig,
  getApiClient: createSelector(
    (state: TRootState) => state.app.axiosBaseConfig,
    (config) => {
      return axios.create(config)
    },
  ),
}
