import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { ParseError, SchemaValidationError } from 'jsoneditor'

import { Breadcrumb, PageError } from '../../components'
import JSONEditorComponent, { JSONEditorMode } from '../../components/JSONEditorComponent'
import { useDispatchStdApiCall } from '../../store/common/standard-request'
import { useAppSelector } from '../../store'
import { decisionTreeVersionApi } from '../../store/DecisionTreeVersionStore'
import { appSelectors } from '../../store/AppStore'
import InputParameters, { InputModel } from './components/InputParameters'
import OutputParameters, { OutputModel } from './components/OutputParameters'
import { DecisionTreeVersionSchema } from '../../store/schemas'
import { decisionTreeModelApi } from '../../store/DecisionTreeModelStore'
import AlertDialog from './components/AlertDialog'

const steps = ['Créer un arbre de décision', 'Input Parameters', 'Output Parameters']

function Index() {
  const { treeId, versionId } = useParams()
  const [json, setJson] = useState<any>([])
  const [refreshEditor, setRefreshEditor] = useState<boolean>(false)
  const [jsonError, setJsonError] = useState<boolean>(false)
  const [inputParametersUI, setInputParametersUI] = useState<Array<InputModel>>([])
  const [outputParametersUI, setOutputParametersUI] = useState<Array<OutputModel>>([])
  const isEditMode = versionId !== undefined
  const [openAlert, setOpenAlert] = React.useState(false)

  const pageError = useAppSelector(appSelectors.getBusinessError)
  const dispatchApi = useDispatchStdApiCall()

  const { setValue, register, getValues } = useForm({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
  })

  const inputParametersToUiInPut = (inputParameters: any) => {
    const inputParameterUi: Array<InputModel> = []
    for (let index = 0; index < inputParameters.length; index += 1) {
      const elements = inputParameters[index]
      const inputUi: InputModel = { ...elements, version: '', model: '' }
      const modeVersionId = elements?.typeId.split('(')[1].split(')')[0]
      dispatchApi(decisionTreeModelApi.fechVersion(modeVersionId)).then((response: any) => {
        inputUi.version = response.data.version
        dispatchApi(decisionTreeModelApi.fetchAll()).then((responseVersion: any) => {
          const element = [...responseVersion.data].find((x) => x?.id === response.data.modelId)
          inputUi.model = element.name
          inputParameterUi.push(inputUi)
          setInputParametersUI(inputParameterUi)
        })
      })
    }
  }

  useEffect(() => {
    dispatchApi(decisionTreeVersionApi.fetchAll(treeId)).then((response: any) => {
      if (response.data.length > 0 && !isEditMode) {
        setJson(response.data[response.data.length - 1]?.steps as any)
        inputParametersToUiInPut(response.data[response.data.length - 1]?.inputParameters)
        setOutputParametersUI(response.data[response.data.length - 1]?.outputParameters as any)
        setRefreshEditor(true)
      }
      if (isEditMode) {
        for (let index = 0; index < response.data.length; index += 1) {
          const element = response.data[index]
          if (element.id === versionId) {
            setInputParametersUI(element?.inputParameters as any)
            inputParametersToUiInPut(element?.inputParameters)
            setOutputParametersUI(element?.outputParameters as any)
            setJson(element?.steps as any)
            setValue('comment', element?.comment)
            setRefreshEditor(true)
          }
        }
      }
    })
  }, [dispatchApi, treeId, isEditMode])

  const [viewMode, setViewMode] = useState<JSONEditorMode>('code')
  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean
  }>({})

  const navigate = useNavigate()
  const createVersion = () => {
    const sendData = DecisionTreeVersionSchema.cast({
      comment: getValues('comment'),
      steps: json,
      inputParameters: inputParametersUI,
      outputParameters: outputParametersUI,
    })

    dispatchApi(decisionTreeVersionApi.create(sendData as any, treeId as string)).then(() => {
      setOpenAlert(true)
    })
  }

  const updateVersion = () => {
    const sendData = DecisionTreeVersionSchema.cast({
      comment: getValues('comment'),
      steps: json,
      inputParameters: inputParametersUI,
      outputParameters: outputParametersUI,
    })
    dispatchApi(decisionTreeVersionApi.update(sendData as any, treeId as string, versionId as string)).then(() => {
      setOpenAlert(true)
    })
  }

  const handleNext = () => {
    if (activeStep === 0 && jsonError) return

    if (activeStep === steps.length - 1) {
      if (!isEditMode) createVersion()
      else updateVersion()
      return
    }

    const newActiveStep =
      activeStep === steps.length - 1 && !(Object.keys(completed).length === steps.length)
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    if (activeStep === 0 && jsonError) return
    setActiveStep(step)
  }

  const { t } = useTranslation()

  const onChangeJSON = (jsonV: any) => {
    setJson(jsonV)
  }

  const onErrorJson = (error: ReadonlyArray<SchemaValidationError | ParseError>) => {
    if (error.length > 0) setJsonError(true)
    else setJsonError(false)
  }

  const onViewModeChange = (event: SelectChangeEvent) => {
    setViewMode(event.target.value as JSONEditorMode)
  }

  const closeAlert = () => {
    setOpenAlert(false)
    navigate(-1)
  }
  const breadcrumb = [
    { path: '/', name: 'home' },
    { path: '/decision-tree', name: 'decisionTree' },
    { path: `/decision-tree/${treeId}/new-version`, name: 'viewVersion' },
  ]

  return (
    <form>
      <AlertDialog
        onClose={closeAlert}
        open={openAlert}
        title={isEditMode ? 'edit with success' : 'create with success'}
        message={
          isEditMode
            ? 'edit with success'
            : "The new version has been successfully created, so don't forget to test it before publishing it"
        }
      />
      <Breadcrumb trees={breadcrumb} />
      <PageError error={pageError} />

      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <div>
        <PageError error={jsonError ? "VEUILLEZ VÉRIFIER L'ABSENCE D'ERREURS DANS L'ARBRE" : null} />
        {activeStep === 0 ? (
          <>
            <TextField
              style={{ marginBottom: 15, marginTop: 15 }}
              placeholder="Commentaire"
              label="Commentaire"
              {...register('comment')}
              fullWidth
            />
            <FormControl sx={{ m: 1, minWidth: 120, margin: 0, marginRight: 15 }}>
              <InputLabel id="select-label">Edit Mode</InputLabel>
              <Select labelId="select-label" value={viewMode} label="View Mode" onChange={onViewModeChange}>
                <MenuItem value="code">Code</MenuItem>
                <MenuItem value="tree">Tree</MenuItem>
                <MenuItem value="form">Form</MenuItem>
              </Select>
            </FormControl>
            <JSONEditorComponent
              json={json}
              onChangeJSON={onChangeJSON}
              onValidationError={onErrorJson}
              mode={viewMode}
              refresh={refreshEditor}
            />
          </>
        ) : null}

        {activeStep === 1 ? (
          <InputParameters treeId={treeId} initData={inputParametersUI} onUpdate={setInputParametersUI} />
        ) : null}

        {activeStep === 2 ? <OutputParameters initData={outputParametersUI} onUpdate={setOutputParametersUI} /> : null}

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            variant="contained"
            color={activeStep !== steps.length - 1 ? 'primary' : 'success'}
            onClick={handleNext}
            sx={{ mr: 1 }}
          >
            {activeStep !== steps.length - 1 ? 'NEXT' : ''.concat(isEditMode ? 'UPDATE' : 'CREATE')}
          </Button>
        </Box>
      </div>
    </form>
  )
}

export default Index
