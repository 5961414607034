import * as React from 'react'
import { Menu, MenuItem, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../store'
import { userSelectors } from '../../../store/UserStore'

export default function UserMenu(): JSX.Element {
  const { t } = useTranslation()
  const menuStyle = { textDecoration: 'none' }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const user = useAppSelector(userSelectors.getUser)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {user.name}
      </Button>
      <Menu id="user-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <a href="/secure/if" style={menuStyle}>
          <MenuItem>{t('menus.user.logout')}</MenuItem>
        </a>
      </Menu>
    </div>
  )
}
