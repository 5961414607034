// eslint-disable-next-line import/no-extraneous-dependencies
import { PublicClientApplication, EventType, InteractionType, RedirectRequest } from '@azure/msal-browser'
import axios from 'axios'
import { msalConfig, protectedResources } from '../authConfig'
import { store } from '../store'
import { userActions } from '../store/UserStore'
import userApi, { JwtToken } from '../store/user-api'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
let msalInstance: PublicClientApplication | null = null

// eslint-disable-next-line import/prefer-default-export
export function initializeMsal(): PublicClientApplication {
  msalInstance = new PublicClientApplication(msalConfig)

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback(async (event) => {
    const myState = store.getState()

    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.interactionType === InteractionType.Redirect)
    ) {
      const { account } = event.payload as RedirectRequest

      if (account && msalInstance) {
        msalInstance.setActiveAccount(account)

        try {
          const responseToken = await msalInstance.acquireTokenSilent({
            account,
            scopes: protectedResources.getTokenAzure.scopes.read,
          })

          const config = myState.app.axiosBaseConfig
          const apiClient = axios.create(config)
          const token: JwtToken = { jwt: responseToken.accessToken }

          const responseIceberg = await userApi.loginWithAzureAd(apiClient, token)

          localStorage.setItem('jwtToken', responseIceberg.jwt)

          if (responseToken) {
            store.dispatch(userActions.setMustAuthenticate(false))
          } else {
            store.dispatch(userActions.setMustAuthenticate(true))
          }
        } catch (error) {
          store.dispatch(userActions.setMustAuthenticate(true))
        }
      }
    }
  })

  return msalInstance
}

export function getMsalInstance() {
  return msalInstance
}
