import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export default function initLanguage() {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
      fallbackLng: 'fr',
      debug: false,
      supportedLngs: ['fr', 'en'],
      interpolation: {
        escapeValue: false,
      },
    })
}
