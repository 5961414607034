import React from 'react'
import { Routes, Route } from 'react-router-dom'
import DecisionTree from '../DecisionTree'
import DecisionTreeViewVersion from '../DecisionTreeVersionView'
import DecisionTreeNewVersion from '../DecisionTreeNewVersion'
import DecisionTreeTest from '../DecisionTreeTest'
import DecisionTreeTrace from '../DecisionTreeTrace'
import AuthPage from '../Auth'
import UnauthorizedPage from './components/UnauthorizedPage'
import RouteGuard from './RouteGuard'
import HomePage from '../Home'

export default function TopLevelRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/admin" element={<RouteGuard requiredPermission="viewDecisionTree" />}>
        <Route path="" element={<DecisionTree />} />
      </Route>

      <Route path="/auth-page" element={<AuthPage />} />
      <Route path="/decision-tree/:treeId" element={<RouteGuard requiredPermission="viewDecisionTree" />}>
        <Route path="view-version/:id" element={<DecisionTreeViewVersion />} />
        <Route path="new-version" element={<DecisionTreeNewVersion />} />
        <Route path="edit-version/:versionId" element={<DecisionTreeNewVersion />} />
        <Route path="test/:versionId" element={<DecisionTreeTest />} />
      </Route>

      <Route path="/decision-tree/trace/:traceId" element={<DecisionTreeTrace />} />
      <Route path="/decision-tree/trace" element={<DecisionTreeTrace />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  )
}
