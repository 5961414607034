import { AxiosError, AxiosInstance, AxiosRequestConfig, Method } from 'axios'
import { useCallback } from 'react'
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, TRootState } from '../index'
import { appActions, appSelectors } from '../AppStore'
import { userActions } from '../UserStore'
import { apiStateActions, IApiCall } from '../ApiStateStore'

export interface TValidationError {
  message: string
  detail: string
  CompilationErrors: Array<string>
}

export function standardErrorHandling(ex: any, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) {
  const error: AxiosError<TValidationError> = ex // cast the error for access
  const errorDetail = error?.response?.data as TValidationError

  if (!error.response) {
    dispatch(appActions.setTechnicalError(error.toString()))
  } else if (error.response.status === 401) {
    window.location.replace(`${process.env.PUBLIC_URL}/unauthorized`)
  } else if (error.response.status === 400 && errorDetail?.message) {
    errorDetail.message += '\n'
    if (errorDetail?.CompilationErrors) {
      const errorF = new Set<string>()
      errorDetail?.CompilationErrors.forEach((value) => {
        errorF.add(value.split('.')[1])
      })
      errorF.forEach((value: string) => {
        errorDetail.message += '\n'.concat(value)
      })
    }
    dispatch(appActions.setBusinessError(errorDetail.message))
  }
  // todo: handle conflict here
  else {
    dispatch(appActions.setTechnicalError(error.response.data.toString()))
  }

  return Promise.reject()
}

export interface UseApiPayload extends IApiCall {
  setAction: any
}

export const createApiCall = (
  apiName: string,
  method: Method,
  url: string,
  setActionFct: any,
  data: any = null,
): UseApiPayload => {
  const setAction = setActionFct ? setActionFct(null) : null
  return { apiName, method, url, setAction, data }
}

export async function standardRequest(
  apiClient: AxiosInstance,
  dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
  payload: UseApiPayload,
) {
  try {
    // reset global errors on modifying requests
    if (payload.method !== 'GET') {
      dispatch(appActions.resetErrors())
    }

    // log api is loading
    dispatch(apiStateActions.logApiCallInitiated(payload))

    // perform actual request
    const ret = await apiClient.request(payload as AxiosRequestConfig)

    // call the setAction if provided. caller might want to set the result into a local state and it might be null
    if (payload.setAction) {
      dispatch({ ...payload.setAction, payload: ret.data })
    }

    dispatch(apiStateActions.logApiCallCompleted(payload))
    return ret
  } catch (ex: any) {
    dispatch(apiStateActions.logApiCallCompleted(payload))
    return standardErrorHandling(ex, dispatch)
  }
}

export const useDispatchStdApiCall = () => {
  const dispatch = useAppDispatch()
  const apiClient = useAppSelector(appSelectors.getApiClient)
  const callback = useCallback(
    (payload: UseApiPayload) => standardRequest(apiClient, dispatch, payload),
    [apiClient, dispatch],
  )
  return callback
}
