import { AxiosInstance, AxiosRequestConfig } from 'axios'
import * as yup from 'yup'

export type JwtToken = {
  jwt: string
}

export interface LoginCredentials {
  username: string
  password: string
}

export const LoginCredentialsSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
})

const userApi = {
  loginWithAzureAd: async (apiClient: AxiosInstance, data: JwtToken): Promise<JwtToken> => {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      baseURL: process.env.REACT_APP_DECISION_TREE_API_URL,
      url: '/GenerateJwtToken',
      data,
      withCredentials: true,
    }

    const response = await apiClient.request(payload)
    return response.data as JwtToken
  },
}

export default userApi
