import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { NodeTrace, TreeNode } from './TreeNodeTypes'

type Props = {
  nodeTracesById: Record<string, NodeTrace>
  selectedNodeId: string | null
  nodesById: Record<string, TreeNode>
}
export default function ConditionPanel({ nodeTracesById, selectedNodeId, nodesById }: Props): JSX.Element {
  if (selectedNodeId !== null) {
    const trace = nodeTracesById[selectedNodeId]
    const node = nodesById[selectedNodeId]

    if (node.decisionTable) {
      return (
        <List>
          {node.decisionTable.map((row) => (
            <div key={row.id}>
              <ListItem selected={trace?.condition === row.condition}>
                <ListItemText primary={row.condition} />
              </ListItem>
            </div>
          ))}
        </List>
      )
    }
    if (node.branches) {
      return (
        <List>
          {node.branches.map((branch) => (
            <div key={branch.id}>
              <ListItem selected={!!nodeTracesById[branch.id]}>
                <ListItemText primary={branch.condition} />
              </ListItem>
            </div>
          ))}
        </List>
      )
    }
    return (
      <List>
        <ListItem selected={nodeTracesById[selectedNodeId]?.conditionResult === true}>
          <ListItemText primary={node.condition} />
        </ListItem>
      </List>
    )
  }
  return <span>Cliquez sur un élément pour voir le détail</span>
}
